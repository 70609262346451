import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/shared/services/authentication.service';

@Component({
    selector: 'nav-profile',
    templateUrl: './nav-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    }
})
export class NavProfileComponent implements OnInit {

    user: any

    constructor(
        private auth: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {
        this.getUser()
     }

    profileMenuList = [
        {
            path: '/apps/users',
            icon: 'feather icon-user',
            item: 'Perfil'
        },
        {
            path: '',
            icon: 'feather icon-power',
            item: 'Cerrar sesión'
        }
    ]

    async getUser(){
        this.user = await this.auth.getSession()
        this.cdr.markForCheck();
     }

    ngOnInit(): void { }

    call(type){
        if(type == 'Cerrar sesión')
            this.auth.logout()
        else
            this.router.navigate(['/apps', 'users', this.user.uuid])
    }

}
