import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class DataService {

    currentAudit:any
    currentCompany:any

    constructor(
        private http: HttpClient
    ) {
    }

    setCurrentAudit(uuid){
        this.http.get(`${environment.apiUrl}audits/${uuid}`).subscribe(audit=>{
            this.currentAudit=audit
            // console.log(this.currentAudit);
        },err=>{
            console.log(err);
        })
    }

    reloadAudit(){
        this.http.get(`${environment.apiUrl}audits/${this.currentAudit.uuid}`).subscribe(audit=>{
            this.currentAudit=audit
            console.log(this.currentAudit);
            
        },err=>{
            console.log(err);
        })
    }

}