import { 
    Component, 
    ChangeDetectionStrategy,
    ViewEncapsulation, 
    OnInit,
    EventEmitter,
    Output,
    ChangeDetectorRef
} from '@angular/core';
import { Router } from '@angular/router';
import { NavMenu } from '@app/shared/types/nav-menu.interface';
import { navConfiguration } from '@app/configs/nav.config'
import { AuthenticationService } from '@app/shared/services/authentication.service';

@Component({
    selector: 'vertical-menu-content',
    templateUrl: 'vertical-menu-content.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class VerticalMenuContentComponent implements OnInit {

    menu : NavMenu[] = [];
    @Output() onNavLinkClick= new EventEmitter();
    user: any
    menuTmp : NavMenu[] = []

    constructor(
        private router: Router, 
        private auth: AuthenticationService,
        private cdr: ChangeDetectorRef
        ) {
    }

    async ngOnInit() {

        await this.getUser()

        let menuTmp = [...navConfiguration];
        let subMenu = menuTmp.find(x => x.title == 'Apps').submenu
        
        if(this.user.accountType == 'Root'){
            subMenu.push(
                {
                    path: '/apps/subscriptions',
                    title: 'Subscripciones',
                    translateKey: 'NAV.SUBSCRIPTIONS',
                    type: 'item',
                    iconType: 'feather',
                    icon: 'icon-credit-card',
                    key: 'apps.subscriptions',
                    submenu: []
                }
            )
        }
        if(this.user.accountType == 'Auditor'){
            subMenu.splice(0, 4)
        }
        // }else{
        //     const subMenu = navConfiguration.find(x => x.title == 'Apps').submenu
        //     if(subMenu.find(x=> x.key == 'apps.subscriptions')){
        //         subMenu.pop()
        //     }
            
        //     if(this.user.accountType == 'Auditor'){
        //         console.log(subMenu)
        //     }
        // }
        
        this.menu = menuTmp
    }

    async getUser(){
        this.user = await this.auth.getSession()
        this.cdr.markForCheck()
     }

    onLinkClick (path: string) {
        this.onNavLinkClick.emit(path)
    }

    isSubNavOpen(key: string) {
        const currentRouteTree = this.getRouteTreeInfo(this.menu)
        return this.isExisted(currentRouteTree, key)
    }

    isExisted(navTree, key: string) {

        if(!navTree) {
            return navTree
        }

        if( navTree.key === key ){
            return true;
        }
        let treeNode; 
        for (let p in navTree) {
            if( navTree.hasOwnProperty(p) && typeof navTree[p] === 'object' ) {
                treeNode = this.isExisted(navTree[p], key);
                if(treeNode){
                    return treeNode;
                }
            }
        }
        return treeNode;
    }
    
    getRouteTreeInfo(nodes: NavMenu[]) {
        let result: NavMenu;
        let found: boolean
        nodes.some((o: NavMenu) => {
            let submenu: NavMenu;
            if (o.path === this.router.url) {
                found = true
                return result = o;
            }
            if (o.submenu && (submenu = this.getRouteTreeInfo(o.submenu))) {
                return result = Object.assign({}, o, { submenu });
            }
        });

        return result;
    }
}