<div class="nav-logo mt-3 d-flex mb-4 ">
    <logo 
        [logoType]="!collapse || quickExpand ? 'logo' : 'fold' "
        [height]="!collapse || quickExpand ? 130 : 40"
        [white]= "color === 'dark'"
        class="w-100"
        style="justify-content: center;"
    ></logo>
</div>
<hr class="m-0">
<perfect-scrollbar class="side-nav-content">
    <vertical-menu-content></vertical-menu-content>
</perfect-scrollbar>
